<template>
    <div class="brand-list">
        <div class="pro_title">{{ proData1.title }}</div>
        <div class="pro_top_box">
            <div class="prodata_body">
                <div class="prodata_item_1" v-for="(item, index) in proData1.list" :key="item.id">
                    <project-item :isShow="true" :dataItem="item"></project-item>
                </div>
            </div>
        </div>
        <div class="pro_title">{{ proData2.title }}</div>
        <div class="pro_query pro_top_box">
            <project-tabs :activeTitle="activeProjectIndustryTitle" :activeIndex="activeProjectIndustry" :activeList="ProjectIndustryList" @setActiveIndex="setProjectIndustry"></project-tabs>
            <project-tabs :activeTitle="activeProjectStatusTitle" :activeIndex="activeProjectStatus" :activeList="ProjectStatusList" @setActiveIndex="setProjectStatus"></project-tabs>
            <project-tabs :activeTitle="activeProjectCategoryTitle" :activeIndex="activeProjectCategory" :activeList="ProjectCategoryList" @setActiveIndex="setProjectCategory"></project-tabs>
            <div class="pro_cascader">
                <div class="pro_query_line">
                    <div class="title">{{ areaTitle }}：</div>
                    <div class="select_lst">
                        <el-select v-model="province_id" placeholder="请选择省" @change="changeProvice(province_id)">
                            <el-option v-for="option in province" :key="option.id" :label="option.name" :value="option.id">{{ option.name }}</el-option>
                        </el-select>
                        <el-select v-model="city_id" placeholder="请选择市" @change="changeCity(city_id)">
                            <el-option v-for="option in city" :key="option.id" :label="option.name" :value="option.id">{{ option.name }}</el-option>
                        </el-select>
                        <el-select v-model="district_id" placeholder="请选择区/县" @change="changeDistrict(district_id)">
                            <el-option v-for="option in district" :key="option.id" :label="option.name" :value="option.id">{{ option.name }}</el-option>
                        </el-select>
                    </div>
                </div>
            </div>
            <div class="pro_cascader pro_ge">
                <div class="pro_query_line tabs_box">
                    <el-tabs v-model="activeIndex4" @tab-click="handleClick4">
                        <el-tab-pane v-for="(item, index) in activeList4" :key="item.id" :label="item.name" :name="item.id.toString()"></el-tab-pane>
                    </el-tabs>
                </div>
            </div>
            <div class="prodata_body">
                <template v-if="proData2.list.length >0 ">
                    <div class="prodata_item_2" v-for="(item, index) in proData2.list" :key="item.id">
                        <project-item class="project_item" :isShow="true" :dataItem="item"></project-item>
                    </div>
                </template>
                <div class="empty" v-else>未查到有效数据！</div>
            </div>
            <div class="prodata_bottom" v-if="showMore">
                <div class="adds" @click="getListData(clickPage)">更多项目</div>
            </div>
        </div>
    </div>
</template>

<script>
    import {projectList,getProjectCategory,getProjectStatus,getProjectType,getProjectIndustry} from "@/api/pc";
    import ProjectItem from "./project_item.vue"
    import ProjectTabs from "./project_tabs.vue"
    import { getArea } from "@/api/address"
    export default {
        components: {
            ProjectItem,
            ProjectTabs
        },
        name: "index",
        data: () => {
            return {
                activeProjectIndustryTitle: "行业",
                activeProjectIndustry: "",
                ProjectIndustryList: [],
                activeProjectStatusTitle: "状态",
                activeProjectStatus: "",
                ProjectStatusList: [],
                activeProjectCategoryTitle: "分类",
                activeProjectCategory: "",
                ProjectCategoryList: [],
                areaTitle: "区域",
                province_id: "",
                city_id: "",
                district_id: "",
                community_id: "",
                province: [],
                city: [],
                district: [],
                activeList4: [],
                activeIndex4: "0",
                loading: false,
                proData1: {
                    title: "推荐项目",
                    list: []
                },
                proData2: {
                    title: "查找更多项目",
                    list: []
                },
                showMore: false,
                filters: {
                    status: '',
                    project_type: '',
                    province_id: 0,
                    city_id: 0,
                    district_id: 0,
                    project_industry_id1:0,
                    project_category_id1:0,
                },
                clickPage:1,
                currentPage:1,
                pageSize:3,
            }
        },
        created() {
            this.getDefaultAreas(0, {level: 0})
        },
        mounted() {
            this.getProjectStatusList();//状态
            this.getProjectTypeList();//类型
            this.getProjectIndustryList();//行业
            this.getProjectCategoryList();//分类
            this.getProjectList();//项目列表
            this.getProjectListTop();//推荐项目列表
        },
        methods: {
            getProjectCategoryList() {
                getProjectCategory({'parent_id':0, return_type:3}).then(res => {
                    if (res.code == 10000 && res.result.list) {
                        this.ProjectCategoryList = res.result.list;
                        this.ProjectCategoryList.unshift({id: "",name: "全部"});
                    }
                    this.loading = false;
                })
                    .catch(err => {
                        this.loading = false;
                        this.$message.error(err.message);
                    });
            },
            getProjectIndustryList() {
                getProjectIndustry({'parent_id':0, return_type:3}).then(res => {
                    if (res.code == 10000 && res.result.list) {
                        this.ProjectIndustryList = res.result.list;
                        this.ProjectIndustryList.unshift({id: "",name: "全部"});

                    }
                    this.loading = false;
                })
                    .catch(err => {
                        this.loading = false;
                        this.$message.error(err.message);
                    });
            },
            getProjectTypeList() {
                getProjectType({}).then(res => {
                    if (res.code == 10000) {
                        this.activeList4 = res.result;
                        this.activeList4.unshift({id: "",name: "全部"});
                    }
                    this.loading = false;
                })
                    .catch(err => {
                        this.loading = false;
                        this.$message.error(err.message);
                    });
            },
            getProjectStatusList() {
                getProjectStatus({}).then(res => {
                    if (res.code == 10000) {
                        this.ProjectStatusList = res.result;
                        this.ProjectStatusList.unshift({id: "",name: "全部"});
                    }
                    this.loading = false;
                })
                .catch(err => {
                    this.loading = false;
                    this.$message.error(err.message);
                });
            },
            getDefaultAreas(pid, obj) {
                getArea({
                    pid: pid
                })
                    .then(res => {
                        if (res.code == 0) {
                            this.province = res.data
                            this.loading = false
                        }
                    })
                    .catch(err => {
                        this.loading = false
                    })
            },
            getAreas(pid, callback) {
                getArea({
                    pid: pid
                })
                    .then(res => {
                        if (res.code == 0) {
                            var data = []
                            res.data.forEach((item, index) => {
                                data.push(item)
                            })
                            if (callback) callback(data)
                        }
                    })
                    .catch(err => {})
            },
            changeProvice(id) {
                this.getAreas(id, data => (this.city = data))
                let obj = {}
                obj = this.province.find(item => {
                    //这里的province就是上面遍历的数据源
                    return item.id === id //筛选出匹配数据
                })
                this.district_id = this.city_id = ""
                this.filters.district_id = this.filters.city_id =0
                this.province_id = this.filters.province_id = id

                this.getProjectList()
            },
            changeCity(id) {
                this.getAreas(id, data => (this.district = data))
                let obj = {}
                obj = this.city.find(item => {
                    //这里的province就是上面遍历的数据源
                    return item.id === id //筛选出匹配数据
                })
                this.district_id = ""
                this.filters.district_id = 0
                this.filters.city_id = this.city_id = id

                this.getProjectList()
            },
            changeDistrict(id) {
                let obj = {}
                obj = this.district.find(item => {
                    //这里的province就是上面遍历的数据源
                    return item.id === id //筛选出匹配数据
                })
                this.filters.district_id = this.district_id = id

                this.getProjectList()
            },
            getProjectList(){
                const params = {
                    page: this.currentPage,
                    page_size: this.pageSize,
                    ...this.filters
                }
                projectList(params || {})
                    .then(res => {
                        if(res.code == 10000) {
                            this.proData2.list = res.result.list
                            if(res.result.count > 3){
                                this.showMore = true
                            }else{
                                this.showMore = false
                            }
                            this.loading = false
                        }
                    })
                    .catch(err => {
                        this.loading = false
                    })
            },
            getProjectListTop(){
                const params = {
                    page: this.currentPage,
                    page_size: 3,
                    is_recommend: 1,
                }
                projectList(params || {})
                    .then(res => {
                        if(res.code == 10000) {
                            this.proData1.list = res.result.list
                            this.loading = false
                        }
                    })
                    .catch(err => {
                        this.loading = false
                    })
            },
            setProjectIndustry(e) {
                this.filters.project_industry_id1 = e
                if(this.filters.project_industry_id1 === '0'){
                  this.filters.project_industry_id1 = '';
                }

                this.getProjectList()
            },
            setProjectStatus(e) {
                this.filters.status = e
                if(this.filters.status === '0'){
                  this.filters.status = '';
                }
                this.getProjectList()
            },
            setProjectCategory(e) {
                this.filters.project_category_id1 = e
                if(this.filters.project_category_id1 === '0'){
                  this.filters.project_category_id1 = '';
                }

                this.getProjectList()
            },
            // 全部、政府、企业
            handleClick4(tab, event){
                let _id = (typeof tab.name !='undefined' ) ? parseInt(tab.name) : 0
                this.filters.project_type = _id
                this.getProjectList()
            },
            getListData(clickPage){
                const params = {
                    page: (clickPage+1),
                    page_size: this.pageSize,
                    ...this.filters
                }
                projectList(params || {})
                .then(res => {
                    if(res.code == 10000) {
                        res.result.list.map((item) =>{
                            this.proData2.list.push(item)
                        })
                        this.loading = false
                        this.clickPage = clickPage+1
                    }
                })
                .catch(err => {
                    this.loading = false
                })

            }
        }
    }
</script>

<style lang="scss">
    .empty{padding: 20px 0px;text-align: center;width: 100%}
    .pro_query {
        background-color: #fff;
        width: 1210px;
        border-radius: 8px;
        margin: 0 auto;
        position: relative;
        padding: 20px;
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        box-sizing: border-box;

        .pro_query_line {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-bottom: 6px;

            .title {
                width: 80px;
                font-size: 14px;
                font-weight: 500;
            }
            .el-tabs {
                width: 1130px;
            }
        }

        .pro_cascader {
            box-sizing: border-box;
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .pro_query_line {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                padding-bottom: 6px;
                .title {
                    width: 62px;
                }
                .select_lst {
                    width: 1056px !important;
                    box-sizing: border-box;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    padding-left: 10px;
                }
            }

            .tabs_box {
                padding-top: 20px;

                .el-tabs__item.is-active {
                    width: auto !important;
                    padding-left: 30px !important;
                    padding-right: 30px !important;
                    background-color: #d81e01 !important;
                    color: #fff !important;
                    border-top-left-radius: 6px !important;
                    border-top-right-radius: 6px !important;
                }
                .el-tabs__item {
                    padding-left: 30px !important;
                    padding-right: 30px !important;
                }
                .el-tabs__nav-wrap::after {
                    content: "" !important;
                    position: absolute !important;
                    left: 0 !important;
                    bottom: 0 !important;
                    width: 100% !important;
                    height: 4px !important;
                    background-color: #d81e01 !important;
                    z-index: 1 !important;
                    border-radius: 2px !important;
                }
            }
        }
    }


    .crumbs {
        width: 1210px;
        margin: 30px auto;
        position: relative;
        padding: 0;
        box-sizing: border-box;
    }
    .brand-list {
        width: 100%;
        position: relative;
        box-sizing: border-box;

        .pro_title {
            width: 100%;
            text-align: center;
            font-size: 20px;
            margin-top: 40px;
            line-height: 20px;
            margin-bottom: 20px;
            font-weight: 600;
            box-sizing: border-box;
        }

        .pro_top_box {
            background-color: #fff;
            width: 1210px;
            border-radius: 8px;
            margin: 0 auto;
            position: relative;
            padding: 20px 8px 20px 10px;
            margin-bottom: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            box-sizing: border-box;

            .prodata_body {
                width: 1190px;
                box-sizing: border-box;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex-wrap: wrap;

                .prodata_item_1,
                .prodata_item_2 {
                    width: 358px;
                    margin-top: 12px;
                    margin-bottom: 12px;
                    margin-left: 16px;
                    margin-right: 16px;
                    box-sizing: border-box;
                }
            }
        }

        .brand-content {
            background-color: #fff;
            width: 1130px;
            border-radius: 8px;
            margin: 0 auto;
            position: relative;
            padding: 40px;
        }
    }

    .prodata_bottom {
        width: 100%;
        height: 120px;
        display: flex;
        justify-content: center;
        align-items: center;

        .adds {
            width: 378px;
            height: 70px;
            font-size: 18px;
            border: 3px solid $base-color;
            color: $base-color;
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 22px;
            font-weight: 600;
        }
        .adds:hover {
            cursor: pointer;
            transform: scale(1.01);
        }
    }

    .el-select .el-input.is-focus .el-input__inner {
        border-color: $base-color !important;
    }
    .el-select-dropdown__item.selected {
        color: $base-color !important;
        font-weight: bold;
    }
    .el-select .el-input__inner:focus {
        border-color: $base-color !important;
    }
    .el-select {
        margin-right: 8px !important;
    }

    .pro_ge {
        padding-bottom: 6px !important;
    }
</style>
